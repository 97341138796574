import { useUserRoles } from '@api/FactoryService';
import { DataError } from '@destination/components';
import React from 'react';
import { Roles } from '@models/Roles';
import SerialRegistrant from '@routes/serial/SerialRegistrant';
import { OrderCreator } from '@routes/order/OrderCreator';

export const Content = () => {
  const { roles, isError, isLoading } = useUserRoles();
  if (isLoading) {
    return <></>;
  }

  if (isError || roles === undefined) {
    return <DataError variant="primary" />;
  }

  if (roles.includes(Roles.ADMIN)) {
    return <SerialRegistrant />;
  } else if (roles.includes(Roles.SHIFT_LEADER)) {
    return <OrderCreator />;
  } else {
    return <DataError variant="primary" />;
  }
};
