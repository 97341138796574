import { NavLink } from 'react-router-dom';
import { Typography } from '@destination/components';

export interface INavigationItem {
  icon: string;
  label: string;
  url: string;
  neededRoles?: string[];
}

export const NavigationItem = ({
  icon,
  label,
  url,
  neededRoles,
  currentRoles
}: INavigationItem & { currentRoles?: string[] }) => {
  let disabled;
  if (neededRoles && !currentRoles) {
    disabled = true;
  } else {
    disabled =
      neededRoles &&
      currentRoles &&
      !neededRoles.some(role => currentRoles.includes(role));
  }

  if (disabled) {
    return <></>;
  }

  return (
    <NavLink to={url}>
      {({ isActive }) => (
        <div
          className="flex py-[3px]"
          data-testid={isActive ? 'active' : 'inactive'}
        >
          <div
            className={`min-h-8 flex w-1 rounded-r-[2px] bg-[#3366FF] ${
              !isActive && 'invisible'
            }`}
          />
          <div className="mx-1 flex w-full items-center hover:rounded-[4px] hover:bg-[#E0F0FF]">
            <div className="p-1">
              <div className="h-4 w-4">
                <img src={icon} alt={`${label} navigation item`} />
              </div>
            </div>
            <Typography
              variant={isActive ? 'smallTitle' : 'description'}
              className="hidden p-1 sm:block"
            >
              {label}
            </Typography>
          </div>
          <div className="invisible w-1" />
        </div>
      )}
    </NavLink>
  );
};
