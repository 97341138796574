import React from 'react';

import { isAxiosError } from 'axios';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Typography } from '@destination/components';
import { useTranslation } from 'react-i18next';

export const ErrorFallback = () => {
  const { t } = useTranslation();

  const error = useRouteError();
  let errorMessage = t('error_fallback.unknown_error');
  if (isRouteErrorResponse(error)) {
    errorMessage = `${error.status} ${error.statusText}`;
  } else if (isAxiosError(error)) {
    errorMessage = `${error.response?.status} ${error.message}`;
  }
  return (
    <div
      data-testid="order-creator"
      className="flex flex-col justify-center"
      style={{ height: '80vh' }}
    >
      <div className="flex justify-center">
        <div role="alert">
          <Typography variant="smallTitle">
            {t('error_fallback.something_went_wrong')}
          </Typography>
          <Typography>{errorMessage}</Typography>
        </div>
      </div>
    </div>
  );
};
