import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/@destination/components/dist/index.css';
import './index.css';
import { SWRConfig } from 'swr';

import './i18n';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { loginRequest, msalInstance } from './authConfig';
import { InteractionType } from '@azure/msal-browser';
import { AlertProvider } from '@destination/components';
import { RouterProvider } from 'react-router';
import { router } from '@routes/router';

//i18next's t method can now return a null value which needs to be prevented.
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const swrConfig = { shouldRetryOnError: false };

// eslint-disable-next-line vitest/require-hook
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <SWRConfig value={swrConfig}>
          <AlertProvider>
            <RouterProvider router={router} />
          </AlertProvider>
        </SWRConfig>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);
