import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Input,
  LoadingSkeleton,
  MultiSelect,
  RadioGroup,
  Select,
  Typography,
  useAlert
} from '@destination/components';
import { IVariant } from '@models/IVariant';
import { IOption } from '@destination/components/dist/components/Select/Select';
import { ReactComponent as PlusIcon } from '@icons/plus_16.svg';
import { ReactComponent as DuplicateIcon } from '@icons/duplicate.svg';
import {
  useAddVariant,
  useVariantEnums,
  useVariants
} from '@api/VariantService';
import { ErrorFallback } from '@components/ErrorFallBack';
import { IMultiSelect } from '@destination/components/dist/components/MultiSelect/MultiSelect';
import { useTranslation } from 'react-i18next';
import { IVariantEnums } from '@models/IVariantEnums';
import { IInput } from '@destination/components/dist/components/Input/Input';

type SelectedRadioOptions = {
  evse_count?: number;
  phases_count?: number;
};
type SelectedOptions = {
  certification?: IOption;
  front_cover?: IOption;
  branding?: IOption;
};

function searchOption<T extends keyof SelectedOptions>(
  prop: T,
  options: IOption[],
  variant: IVariant
) {
  const option = options?.find(item => item.value === variant?.[prop]);
  return { [prop]: option };
}

function searchRadioOption<T extends keyof SelectedRadioOptions>(
  prop: T,
  options: IOption[],
  variant: IVariant
) {
  const option = options?.find(item => item.value === variant?.[prop]);
  return { [prop]: option?.value };
}

function searchOptionBoardOptions(
  options: IOption[],
  variant: IVariant
): IOption[] | null {
  if (variant?.option_boards === undefined) {
    return null;
  }
  return variant?.option_boards
    .map(value => options.find(item => item.value === value))
    .filter(item => item !== undefined)
    .map(item => item as IOption)
    .concat();
}

type IVariantOption = IOption & IVariant;
const VariantsView = () => {
  const [selectedOptions, setSelectedOptions] = useState<
    SelectedOptions & SelectedRadioOptions
  >({});
  const [variant, setVariant] = useState<IVariantOption | null>(null);
  const [selectedOptionBoards, setSelectedOptionBoards] = useState<
    IOption[] | null
  >(null);
  const [newVariant, setNewVariant] = useState<IVariant>();
  const { t } = useTranslation();

  const existingVariantAPI = useVariants();
  const variantEnumAPI = useVariantEnums();

  const isLoading = existingVariantAPI.isLoading || variantEnumAPI.isLoading;
  const isError = existingVariantAPI.isError || variantEnumAPI.isError;

  if (isError) {
    return <ErrorFallback />;
  }
  if (isLoading) {
    return (
      <div className="pl-[160px] pr-[40px] space-y-5  mt-[120px]">
        <LoadingSkeleton height={500} />
      </div>
    );
  }
  const enums: IVariantEnums = variantEnumAPI.enums ?? {
    option_board: [],
    certification: [],
    front_cover: [],
    branding: []
  };

  const existingVariantOptions: IVariantOption[] = existingVariantAPI.variants
    ? existingVariantAPI.variants.map(data => ({
        ...data.configuration,
        name: data.name,
        value: data.name,
        label: data.name
      }))
    : [];

  const phasesOptions = [
    { value: 1, label: t('variant.1_phase') },
    { value: 2, label: t('variant.2_phase') },
    { value: 3, label: t('variant.3_phase') }
  ];

  const evsesOptions = [
    { value: 1, label: t('variant.1_evse') },
    { value: 2, label: t('variant.2_evse') }
  ];

  const clear = () => {
    setNewVariant({});
    setVariant(null);
    setSelectedOptions({});
    setSelectedOptionBoards(null);
  };
  const duplicate = () => {
    setNewVariant(variant as IVariant);
    setVariant(null);
  };

  const overview =
    variant === null ? (
      <EditableView
        newVariant={newVariant}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        existingVariantOptions={existingVariantOptions}
        selectedOptionBoards={selectedOptionBoards}
        setSelectedOptionBoards={setSelectedOptionBoards}
        variantEnums={enums}
        phasesOptions={phasesOptions}
        evsesOptions={evsesOptions}
        clear={clear}
      />
    ) : (
      <ReadOnlyView
        variant={variant}
        selectedOptions={selectedOptions}
        selectedOptionBoards={selectedOptionBoards}
        phasesOptions={phasesOptions}
        evsesOptions={evsesOptions}
        duplicate={duplicate}
      />
    );

  const chooseVariant = (variant: IVariantOption) => {
    const certification = searchOption(
      'certification',
      enums.certification,
      variant
    );
    const frontCover = searchOption('front_cover', enums.front_cover, variant);
    const branding = searchOption('branding', enums.branding, variant);
    const evses = searchRadioOption('evse_count', evsesOptions, variant);
    const phases = searchRadioOption('phases_count', phasesOptions, variant);
    setSelectedOptionBoards(
      searchOptionBoardOptions(enums.option_board, variant)
    );
    const options = {
      ...certification,
      ...frontCover,
      ...branding,
      ...evses,
      ...phases
    };
    setSelectedOptions(options);
    setVariant(variant);
  };

  return (
    <div className="pl-[160px] pr-[40px] space-y-5 ">
      <Typography variant="pageTitle">{t('variant.add_variant')}</Typography>
      <div className="flex">
        <div className="ml-2 w-[300px]">
          <Select
            placeholder={t('variant.existing_variants')}
            options={existingVariantOptions}
            selected={variant}
            onChange={event => chooseVariant(event)}
          ></Select>
        </div>
        <Button
          disabled={variant === null}
          className="ml-5 justify-self-start"
          onClick={() => clear()}
        >
          {t('variant.clear')}
        </Button>
      </div>
      <Typography variant="sectionTitle">{t('variant.overview')}</Typography>
      {overview}
    </div>
  );
};

export default VariantsView;

interface ReadOnlyViewProps {
  variant: IVariantOption;
  selectedOptions: SelectedOptions & SelectedRadioOptions;
  selectedOptionBoards: IOption[] | null;
  duplicate: () => void;
  phasesOptions: IOption[];
  evsesOptions: IOption[];
}

const ReadOnlyView = ({
  variant,
  selectedOptions,
  selectedOptionBoards,
  duplicate,
  phasesOptions,
  evsesOptions
}: ReadOnlyViewProps) => {
  const { t } = useTranslation();
  const { notify } = useAlert();
  const showTip = () => {
    notify({
      header: t('variant.read_only'),
      variant: 'message'
    });
  };
  return (
    <Card
      className="grid grid-rows-6 grid-flow-col gap-7 pt-8"
      data-testid="read-only"
    >
      <button className="contents" onClick={() => showTip()}>
        <Input
          readOnly={true}
          id="name"
          label={t('variant.name')}
          value={variant.name}
        />
        <Input
          readOnly={true}
          id="hw_revision"
          label={t('variant.hw_revision')}
          value={variant.hw_revision}
        />
        <Input
          readOnly={true}
          id="hw_model"
          label={t('variant.hw_model')}
          value={variant.hw_model}
        />
        <Input
          readOnly={true}
          id="certification"
          label={t('variant.certification')}
          value={selectedOptions.certification?.label}
        />
        <Input
          readOnly={true}
          id="voltage"
          label={t('variant.voltage')}
          value={variant.rated_voltage}
        />
        <Input
          readOnly={true}
          id="current"
          label={t('variant.current')}
          value={variant.rated_current}
        />
        <Input
          readOnly={true}
          id="power"
          label={t('variant.power')}
          value={variant.rated_power}
        />
        <Input
          readOnly={true}
          id="branding"
          label={t('variant.branding')}
          value={selectedOptions.branding?.label}
        />
        <Input
          readOnly={true}
          id="front_cover"
          label={t('variant.front_cover')}
          value={selectedOptions.front_cover?.label}
        />
        <Checkbox
          readOnly={true}
          id="tethered"
          variant="alternative"
          label={t('variant.tethered')}
          checked={variant?.tethered}
          data-testid="tethered"
        />
        <RadioGroup
          variant="alternative"
          value={variant.phases_count ? variant.phases_count : null}
          options={phasesOptions}
          onChange={() => null}
        />
        <Checkbox
          readOnly={true}
          id="user_modem_present"
          variant="alternative"
          data-testid="user_modem_present"
          label={t('variant.user_modem_present')}
          checked={variant?.user_modem_4g_present}
        />
        <Checkbox
          readOnly={true}
          id="lcd_present"
          variant="alternative"
          label={t('variant.lcd_present')}
          checked={variant?.lcd_present}
          data-testid="lcd_present"
        />
        <RadioGroup
          variant="alternative"
          value={variant.evse_count ? variant.evse_count : null}
          options={evsesOptions}
          onChange={() => null}
        />
        <Input
          readOnly={true}
          id="option_board"
          label={t('variant.option_board')}
          value={selectedOptionBoards?.map(item => item.label).concat()}
        />
        <Input
          readOnly={true}
          id="product_id"
          label={t('variant.product_id')}
          value={variant.product_id}
        />
        <Input
          readOnly={true}
          id="global_id"
          label={t('variant.global_id')}
          value={variant.global_id}
        />
        <Input
          readOnly={true}
          id="ean"
          label={t('variant.ean')}
          value={variant.ean}
        />
      </button>
      <Button
        className="justify-self-end row-end-7"
        onClick={() => duplicate()}
        icon={<DuplicateIcon />}
      >
        {t('variant.duplicate')}{' '}
      </Button>
    </Card>
  );
};

interface EditableViewProps {
  newVariant: IVariant | undefined;
  selectedOptions: SelectedOptions & SelectedRadioOptions;
  setSelectedOptions: (
    value:
      | ((
          prevState: SelectedOptions & SelectedRadioOptions
        ) => SelectedOptions & SelectedRadioOptions)
      | (SelectedOptions & SelectedRadioOptions)
  ) => void;
  existingVariantOptions: (IVariant & IOption)[] | undefined;
  variantEnums: {
    front_cover: IOption[];
    branding: IOption[];
    option_board: IOption[];
    certification: IOption[];
  };
  selectedOptionBoards: IOption[] | null;
  setSelectedOptionBoards: (
    value:
      | ((prevState: IOption[] | null) => IOption[] | null)
      | IOption[]
      | null
  ) => void;
  clear: () => void;
  phasesOptions: IOption[];
  evsesOptions: IOption[];
}

const EditableView = ({
  newVariant,
  selectedOptions,
  setSelectedOptions,
  existingVariantOptions,
  variantEnums,
  selectedOptionBoards,
  setSelectedOptionBoards,
  clear,
  phasesOptions,
  evsesOptions
}: EditableViewProps) => {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(new Map());

  const [variant, setVariant] = useState<IVariant>(
    newVariant ?? ({} as IVariant)
  );
  const { addVariant } = useAddVariant();
  const { notify } = useAlert();
  const set = <T extends keyof IVariant>(prop: T, value: IVariant[T]) => {
    variant[prop] = value;
    setVariant({ ...variant });
  };
  const setOption = <T extends keyof SelectedOptions>(
    prop: T,
    value: IOption
  ) => {
    setFormError(prop, false);
    setSelectedOptions({ ...selectedOptions, [prop]: value });
    set(prop, value.value as keyof IVariant);
  };

  const setRadioOption = <T extends keyof SelectedRadioOptions>(
    prop: T,
    value: number
  ) => {
    setFormError(prop, false);
    setSelectedOptions({ ...selectedOptions, [prop]: value });
    set(prop, value);
  };

  const getRadioOption = useCallback(
    <T extends keyof SelectedRadioOptions>(prop: T) => {
      const selectedOption = selectedOptions[prop];
      if (selectedOption === undefined) {
        return null;
      }
      return selectedOption;
    },
    [selectedOptions]
  );

  const getOption = useCallback(
    <T extends keyof SelectedOptions>(prop: T) => {
      const selectedOption = selectedOptions[prop];
      if (selectedOption === undefined) {
        return null;
      }
      return selectedOption;
    },
    [selectedOptions]
  );

  const submit = () => {
    addVariant(variant).then(response => {
      if (response?.error === undefined) {
        notify({
          header: t('variant.added'),
          variant: 'success'
        });
        setVariant({});
        clear();
      } else {
        notify({
          header: t('variant.added_failed'),
          variant: 'error'
        });
      }
    });
  };

  const setFormError = (id: string, error: boolean) => {
    let hasChanged;
    if (error) {
      if (!formErrors.has(id)) {
        formErrors.set(id, error);
        hasChanged = true;
      }
    } else {
      hasChanged = formErrors.delete(id);
    }
    if (hasChanged) {
      setFormErrors(new Map(formErrors));
    }
  };

  const hasSameName = (name: string): boolean => {
    return (
      existingVariantOptions?.find(item => item.label === name) !== undefined
    );
  };

  ['certification', 'branding', 'front_cover'].forEach(prop => {
    if (getOption(prop as keyof SelectedOptions) === null) {
      formErrors.set(prop, true);
    }
  });
  ['evse_count', 'phases_count'].forEach(prop => {
    if (getRadioOption(prop as keyof SelectedRadioOptions) === null) {
      formErrors.set(prop, true);
    }
  });

  return (
    <Card
      className="grid grid-rows-6 grid-flow-col gap-7 pt-8"
      data-testid="edit"
    >
      <ValidationInput
        id="new_name"
        label={t('variant.name')}
        value={variant?.name ?? ''}
        extraError={hasSameName}
        onChange={event => set('name', event.target.value)}
        minLength={1}
        setFormError={setFormError}
      />
      <ValidationInput
        id="new_hw_revision"
        label={t('variant.hw_revision')}
        value={variant?.hw_revision ?? ''}
        onChange={event => set('hw_revision', event.target.value)}
        minLength={1}
        maxLength={4}
        setFormError={setFormError}
      />
      <ValidationInput
        id="new_hw_model"
        label={t('variant.hw_model')}
        value={variant?.hw_model ?? ''}
        onChange={event => set('hw_model', event.target.value)}
        minLength={1}
        maxLength={32}
        setFormError={setFormError}
      />
      <Select
        selected={getOption('certification')}
        label={t('variant.certification')}
        onChange={option => setOption('certification', option)}
        options={variantEnums.certification}
      />
      <ValidationNumberInput
        id="new_voltage"
        label={t('variant.voltage')}
        value={variant?.rated_voltage ?? ''}
        onChange={event => set('rated_voltage', +event.target.value)}
        minValue={0}
        setFormError={setFormError}
      />
      <ValidationNumberInput
        id="new_current"
        label={t('variant.current')}
        value={variant?.rated_current ?? ''}
        minValue={0}
        onChange={event => set('rated_current', +event.target.value)}
        setFormError={setFormError}
      />
      <ValidationNumberInput
        id="new_power"
        label={t('variant.power')}
        value={variant?.rated_power ?? ''}
        minValue={0}
        onChange={event => set('rated_power', +event.target.value)}
        setFormError={setFormError}
      />
      <Select
        selected={getOption('branding')}
        label={t('variant.branding')}
        onChange={option => setOption('branding', option)}
        options={variantEnums.branding}
      />
      <Select
        selected={getOption('front_cover')}
        label={t('variant.front_cover')}
        onChange={option => setOption('front_cover', option)}
        options={variantEnums.front_cover}
      />
      <Checkbox
        id="new_tethered"
        variant="alternative"
        label={t('variant.tethered')}
        checked={variant?.tethered ?? false}
        onClick={() => set('tethered', !variant?.tethered)}
      />
      <RadioGroup
        variant="alternative"
        value={getRadioOption('phases_count')}
        onChange={value => setRadioOption('phases_count', +value)}
        options={phasesOptions}
      />
      <Checkbox
        id="new_user_modem_present"
        variant="alternative"
        label={t('variant.user_modem_present')}
        checked={variant?.user_modem_4g_present ?? false}
        onClick={() =>
          set('user_modem_4g_present', !variant?.user_modem_4g_present)
        }
      />
      <Checkbox
        id="new_lcd_present"
        variant="alternative"
        label={t('variant.lcd_present')}
        checked={variant?.lcd_present ?? false}
        onClick={() => set('lcd_present', !variant?.lcd_present)}
      />
      <RadioGroup
        variant="alternative"
        value={getRadioOption('evse_count')}
        onChange={value => setRadioOption('evse_count', +value)}
        options={evsesOptions}
      />
      <MultipleSelect
        selected={selectedOptionBoards}
        label={t('variant.option_board')}
        onChange={options => setSelectedOptionBoards(options)}
        options={variantEnums.option_board}
      />
      <ValidationInput
        id="new_product_id"
        label={t('variant.product_id')}
        value={variant?.product_id ?? ''}
        onChange={event => set('product_id', event.target.value)}
        minLength={3}
        setFormError={setFormError}
      />
      <ValidationInput
        id="new_global_id"
        label={t('variant.global_id')}
        value={variant?.global_id ?? ''}
        onChange={event => set('global_id', event.target.value)}
        minLength={3}
        setFormError={setFormError}
      />
      <ValidationInput
        id="new_ean"
        label={t('variant.ean')}
        onChange={event => set('ean', event.target.value)}
        value={variant?.ean ?? ''}
        minLength={2}
        setFormError={setFormError}
      />
      <Button
        disabled={formErrors.size !== 0}
        className="justify-self-end row-end-7"
        onClick={() => submit()}
        icon={<PlusIcon />}
      >
        {t('variant.save')}
      </Button>
    </Card>
  );
};

interface INumberValidationInput {
  value?: number | '' | string;
  minValue?: number;
  setFormError?: (id: string, error: boolean) => void;
}

const ValidationNumberInput = ({
  minValue,
  setFormError,
  value,
  id,
  ...props
}: IInput & INumberValidationInput) => {
  let error = false;
  if (value !== undefined && typeof value !== 'string' && isNaN(value)) {
    value = '';
  }
  if (value !== undefined) {
    error =
      typeof value === 'string' || (minValue !== undefined && value < minValue);
  }
  if (setFormError !== undefined) {
    setFormError(id, error);
  }

  return (
    <Input {...props} id={id} value={value} error={value !== '' && error} />
  );
};

interface IValidationInput {
  value: string;
  minLength?: number;
  maxLength?: number;
  setFormError?: (id: string, error: boolean) => void;
  extraError?: (value: string) => boolean;
}

const ValidationInput = ({
  minLength,
  maxLength,
  setFormError,
  extraError,
  value,
  id,
  ...props
}: IInput & IValidationInput) => {
  let error = false;
  if (value !== undefined) {
    const minLengthError = minLength !== undefined && value.length < minLength;
    const maxLengthError = maxLength !== undefined && value.length > maxLength;
    error = minLengthError || maxLengthError;
  }
  if (extraError !== undefined) {
    error = error || extraError(value);
  }
  if (setFormError !== undefined) {
    setFormError(id, error);
  }

  return (
    <Input {...props} id={id} value={value} error={value !== '' && error} />
  );
};

const MultipleSelect = ({ selected, label, ...props }: IMultiSelect) => {
  const empty = selected === null || selected.length === 0;
  const currentLabel = empty ? undefined : label;
  const placeholder = empty ? label : undefined;
  return (
    <MultiSelect
      selected={selected}
      label={currentLabel}
      placeholder={placeholder}
      {...props}
    />
  );
};
