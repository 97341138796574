import { ENUMS_PATH, VARIANT_PATH } from '@api/paths';
import { fetcher, post } from '@utils/swr-utils';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { IVariant } from '@models/IVariant';
import { IVariantEnums } from '@models/IVariantEnums';
import { IHardwareVariant } from '@models/IHardwareVariant';

export function useVariants() {
  const { data, error, isLoading } = useSWR<IHardwareVariant[]>(
    VARIANT_PATH,
    (url: string) => fetcher(url)
  );
  return {
    variants: data,
    isLoading,
    isError: error
  };
}

export function useAddVariant() {
  const { trigger } = useSWRMutation(VARIANT_PATH, post);

  const addVariant = async (variant: IVariant) => {
    const response = await trigger({
      payload: {
        name: variant.name,
        configuration: {
          ...variant,
          hw_variant: 'Prod'
        }
      }
    });
    return { error: response?.error };
  };

  return { addVariant };
}

export function useVariantEnums() {
  const { data, error, isLoading } = useSWR<IVariantEnums>(
    ENUMS_PATH,
    (url: string) => fetcher(url)
  );
  return {
    enums: data,
    isLoading,
    isError: error
  };
}
