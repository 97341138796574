import { Outlet } from 'react-router-dom';
import React from 'react';
import { Sidebar } from '@components/navigation/Sidebar';
import { Navigation } from '@components/navigation/Navigation';

export const Root = () => {
  return (
    <div data-testid="page-content" className="flex">
      <Sidebar>
        <Navigation />
      </Sidebar>
      <main className="grow py-[53px] px-[70px]">
        <Outlet />
      </main>
    </div>
  );
};
