import { FACTORY_PATH, ORDER_PATH, ROLE_PATH, SERIAL_PATH } from '@api/paths';
import { fetcher, post } from '@utils/swr-utils';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { IFactory } from '@models/IFactory';
import { IOrder } from '@models/IOrder';
import { ISerial } from '@models/ISerial';

export function useRegisterSerialNumber() {
  const { trigger } = useSWRMutation(SERIAL_PATH, post);

  const registerSerialNumber = async (factoryId: number) => {
    const response = await trigger({
      payload: {
        factory_id: factoryId
      }
    });
    return { data: response?.data as ISerial, error: response?.error };
  };

  return { registerSerialNumber };
}

export function useGenerateQRCodes() {
  const { trigger } = useSWRMutation(ORDER_PATH, post);

  const generateQRCodes = async (amount: number, variant: string) => {
    const response = await trigger({
      payload: { amount, hw_variant: variant }
    });
    return { data: response?.data as IOrder, error: response?.error };
  };

  return { generateQRCodes };
}

export function useFactories() {
  const { data, error, isLoading } = useSWR<IFactory[]>(
    FACTORY_PATH,
    (url: string) => fetcher(url)
  );
  return {
    factories: data,
    isLoading,
    isError: error
  };
}
export function useUserRoles() {
  const { data, error, isLoading } = useSWR<string[]>(
    ROLE_PATH,
    (url: string) => fetcher(url)
  );
  return {
    roles: data,
    isLoading,
    isError: error
  };
}
