import Axios from 'axios';
import { getToken } from '@api/api';

Axios.defaults.baseURL = window.location.origin + '/api/v1';
Axios.defaults.withCredentials = false;

export const fetcher = async (url: string) => {
  const token = await getToken();
  const res = await Axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  });

  return res.data;
};

export const post = async (
  url: string,
  { arg }: { arg: { payload: object } }
) => {
  const { payload } = arg;

  try {
    const token = await getToken();
    const { data } = await Axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { data, error: undefined };
  } catch (error) {
    return { error, data: undefined };
  }
};
