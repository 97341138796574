import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend'; // have a own http fallback

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
  projectId: isProduction
    ? import.meta.env.VITE_PROD_LOCIZE_PROJECT_ID
    : import.meta.env.VITE_DEV_LOCIZE_PROJECT_ID,
  apiKey: isProduction
    ? import.meta.env.VITE_PROD_LOCIZE_APIKEY
    : import.meta.env.VITE_DEV_LOCIZE_APIKEY,
  referenceLng: 'en',
  version: isProduction
    ? import.meta.env.VITE_PROD_LOCIZE_VERSION
    : import.meta.env.VITE_DEV_LOCIZE_VERSION
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,vitest/require-hook
let backends: any[] = [LocalStorageBackend, HttpApi];
// eslint-disable-next-line @typescript-eslint/no-explicit-any,vitest/require-hook
let backendOptions: any[] = [
  {
    expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
  },
  {
    loadPath: '/locales/{{lng}}/{{ns}}.json' // http api load path for my own fallback
  }
];
if (!isProduction) {
  backends = [LocizeBackend];
  backendOptions = [locizeOptions];
}

// declare custom type options so the return is always a string.

void i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(ChainedBackend)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<ChainedBackendOptions>({
    returnNull: false,
    debug: !isProduction,
    fallbackLng: 'en',
    defaultNS: 'DS.FactoryServices.CloudFactoryProcessService',
    saveMissing: !isProduction, // you should not use saveMissing in production
    // keySeparator: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      backends,
      backendOptions
    },
    locizeLastUsed: locizeOptions
  });

export default i18n;
