import {
  Button,
  Dialog,
  Input,
  Select,
  useAlert
} from '@destination/components';
import { useTranslation } from 'react-i18next';
import { useGenerateQRCodes } from '@api/FactoryService';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { IOption } from '@destination/components/dist/components/Select/Select';
import { IOrder } from '@models/IOrder';
import { useVariants } from '@api/VariantService';

export function OrderCreator() {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedVariant, selectVariant] = useState<IOption | null>(null);
  const [amount, setAmount] = useState(0);
  const { t } = useTranslation();

  const { generateQRCodes } = useGenerateQRCodes();
  const { variants } = useVariants();
  const { notify } = useAlert();

  const variantOptions = variants
    ? variants.map(variant => ({
        label: variant.name,
        value: variant.name
      }))
    : [];

  const generate = async () => {
    if (selectedVariant !== null) {
      const { data, error } = await generateQRCodes(
        amount,
        selectedVariant.value as string
      );
      if (error) {
        notify({
          header: t('order.generate_qr_failed'),
          variant: 'error'
        });
      } else {
        openForPrinting(data);
      }
    }
    setShowDialog(false);
  };

  function openForPrinting(order: IOrder) {
    const byteCharacters = atob(order.document.data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download =
      `${order.order_id}_${amount}_${selectedVariant?.label}.xlsx`.replaceAll(
        ' ',
        '_'
      );

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(blobUrl);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(+event.target.value)) {
      setAmount(+event.target.value);
    }
  };

  return (
    <>
      <Container>
        <div className="grid grid-flow-row gap-16">
          <div className="flex space-x-16">
            <div className="w-[100px]">
              <Label label={t('order.amount')} />
              <Input
                data-testid="amount"
                id="amount"
                onChange={handleChange}
                value={'' + amount}
              />
            </div>
            <div className="min-w-[250px]">
              <Label label={t('order.variant.label')} />
              <Select
                placeholder={t('order.select')}
                data-testid="variants"
                options={variantOptions}
                selected={selectedVariant}
                onChange={selectVariant}
              ></Select>
            </div>
          </div>
          <Button
            onClick={() => setShowDialog(true)}
            disabled={amount === 0 || selectedVariant === null}
          >
            {t('order.generate')}
          </Button>
        </div>
      </Container>
      {showDialog && (
        <Dialog
          isOpen={showDialog}
          header={t('order.confirm.header')}
          content={t('order.confirm.content', {
            variant: selectedVariant?.label,
            amount
          })}
          onCancel={() => setShowDialog(false)}
          onConfirm={() => generate()}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
}

const Label = ({ label }: { label: string }) => {
  return <label className="tw-absolute -tw-translate-y-[30px] ">{label}</label>;
};

const Container = ({ children }: { children: ReactNode }) => {
  return (
    <div
      data-testid="order-creator"
      className="flex flex-col justify-center"
      style={{ height: '80vh' }}
    >
      <div className="flex justify-center">{children}</div>
    </div>
  );
};
