import { useTranslation } from 'react-i18next';
import {
  INavigationItem,
  NavigationItem
} from '@components/navigation/NavigationItem';
import ChargersIcon from '@icons/chargers.svg';
import ConfigurationsIcon from '@icons/configuration.svg';
import AccountIcon from '@icons/account.svg';
import ArrowRightIcon from '@icons/arrowRightIcon.svg';
import React, { useState } from 'react';
import { Typography } from '@destination/components';
import { msalInstance } from '../../authConfig';
import { useUserRoles } from '@api/FactoryService';
import { Roles } from '@models/Roles';

export const Navigation = () => {
  const [lang, setLang] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const { roles, isError, isLoading } = useUserRoles();

  const currentAccount = msalInstance.getAllAccounts()[0];
  const name = currentAccount.name;

  if (
    lang === null &&
    currentAccount &&
    currentAccount.idTokenClaims &&
    currentAccount.idTokenClaims.language
  ) {
    const language: string = currentAccount.idTokenClaims.language as string;
    setLang(language);
    i18n.changeLanguage(language);
  }

  let navigationItems: INavigationItem[] = [];
  if (
    !isLoading &&
    !isError &&
    roles !== undefined &&
    roles.includes(Roles.SHIFT_LEADER)
  ) {
    navigationItems = [
      {
        icon: ChargersIcon,
        label: t('navigation.order'),
        url: '/'
      },
      {
        icon: ConfigurationsIcon,
        label: t('navigation.variants'),
        url: '/variants'
      }
    ];
  }

  return (
    <nav className="flex h-full flex-col justify-between">
      <div className="mt-[84px]">
        {navigationItems.map(({ icon, label, url }) => (
          <NavigationItem key={url} icon={icon} label={label} url={url} />
        ))}
      </div>
      <div className="mb-12">
        <div className="flex w-full items-center">
          <div className="p-1">
            <div className="ml-2 h-4 w-4">
              <img src={AccountIcon} alt={`Account`} />
            </div>
          </div>
          <Typography variant="description" className="hidden p-1 sm:block">
            {name}
          </Typography>
        </div>
        <NavigationItem
          key="logout"
          icon={ArrowRightIcon}
          label={t('account.logout')}
          url="/logout"
        />
      </div>
    </nav>
  );
};
