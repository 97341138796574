import { createBrowserRouter, Navigate } from 'react-router-dom';

import { Root } from './root';
import { Logout } from '@components/account/Logout';
import React from 'react';
import { Content } from '@routes/Content';
import VariantsView from '@routes/variants/VariantsView';

export const router = createBrowserRouter([
  { path: '/auth-response', element: <Navigate to="/" replace /> },
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Content />
      },
      {
        path: 'variants',
        element: <VariantsView />
      },
      {
        path: 'logout',
        element: <Logout />
      }
    ]
  }
]);
