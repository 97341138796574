import {
  Button,
  Dialog,
  Input,
  LoadingSkeleton,
  Select,
  useAlert
} from '@destination/components';
import { IOption } from '@destination/components/dist/components/Select/Select';
import React, { ReactNode, useState } from 'react';
import { useFactories, useRegisterSerialNumber } from '@api/FactoryService';
import { useTranslation } from 'react-i18next';

function SerialRegistrant() {
  const [showDialog, setShowDialog] = useState(false);
  const [serial, setSerial] = useState<string>();
  const [selectedFactory, selectFactory] = useState<IOption | null>(null);

  const { t } = useTranslation();
  const { factories, isLoading } = useFactories();
  const { registerSerialNumber } = useRegisterSerialNumber();
  const { notify } = useAlert();

  if (isLoading) {
    return (
      <Container>
        <LoadingSkeleton height={255} />
      </Container>
    );
  }

  const factoryOptions = factories
    ? factories.map(factory => ({
        label:
          (factory.translation_key
            ? t('serial.' + factory.translation_key)
            : factory.name) || factory.name,
        value: factory.id
      }))
    : [];

  function getSelectedFactoryOption(): IOption | null {
    if (factoryOptions.length === 0) {
      return null;
    }
    return selectedFactory ?? factoryOptions[0];
  }

  const selectedFactoryOption = getSelectedFactoryOption();

  async function register() {
    const { data, error } = await registerSerialNumber(
      selectedFactoryOption?.value as number
    );
    if (error) {
      notify({
        header: t('serial.notify_failure'),
        variant: 'error'
      });
    } else {
      notify({
        header: t('serial.notify_success'),
        variant: 'success'
      });
    }
    setSerial(data.token);
    setShowDialog(false);
  }

  const changeFactory = (factory: IOption) => {
    setSerial('');
    selectFactory(factory);
  };

  return (
    <>
      <Container>
        <div className="mb-2">
          <label className="tw-absolute -tw-translate-y-[30px] ">
            {t('serial.factory')}
          </label>
          <Select
            data-testid="factories"
            options={factoryOptions}
            selected={selectedFactoryOption}
            onChange={changeFactory}
          />
        </div>

        <div className="flex flex-row">
          <Button
            disabled={selectedFactoryOption === null}
            onClick={() => setShowDialog(true)}
          >
            {t('serial.register')}
          </Button>
          <div className="pl-6 w-full">
            <Input
              onChange={() => {
                //noop
              }}
              data-testid="serial"
              disabled={serial === undefined || serial === ''}
              id="serial"
              label={t('serial.number')}
              value={serial}
            />
          </div>
        </div>
      </Container>
      {showDialog && (
        <Dialog
          isOpen={showDialog}
          header={t('serial.confirm.header')}
          content={t('serial.confirm.content', {
            serial,
            factory: selectedFactoryOption?.label
          })}
          onCancel={() => setShowDialog(false)}
          onConfirm={() => register()}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
}

const Container = ({ children }: { children: ReactNode }) => {
  return (
    <div
      data-testid="serial-register"
      className="flex flex-col justify-center"
      style={{ height: '80vh' }}
    >
      <div className="flex justify-center">
        <div className="grid grid-flow-row gap-10 w-2/5">{children}</div>
      </div>
    </div>
  );
};

export default SerialRegistrant;
