import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ABBLogo from '@icons/abb.svg';

interface ISidebar {
  children: ReactNode;
}

export const Sidebar = ({ children }: ISidebar) => {
  return (
    <aside className="fixed h-screen w-[150px] bg-white shadow-sideBar">
      <div className="absolute left-1 top-[22px] z-20 sm:left-4 sm:top-5">
        <Link to="/">
          <img className="h-3 sm:h-4" src={ABBLogo} alt="ABB logo" />
        </Link>
      </div>
      <div className="h-full">{children}</div>
    </aside>
  );
};
